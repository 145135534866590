/* line 1, /Users/eric/Sites/GPSApp/app/styles/app.scss */
.loader_container {
  position: absolute;
  text-align: center;
  padding: 0;
  font-size: 50px;
  top: 100px;
  display: block;
  width: 100%; }

/* line 12, /Users/eric/Sites/GPSApp/app/styles/app.scss */
#alert_success_upload, #alert_error_upload {
  display: none; }

/* line 18, /Users/eric/Sites/GPSApp/app/styles/app.scss */
#maskOverlay {
  content: "";
  background-color: #f8f8f8;
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 3;
  opacity: 0.5;
  top: 0;
  left: 0; }

/* line 30, /Users/eric/Sites/GPSApp/app/styles/app.scss */
#alertOffline {
  z-index: 4;
  position: absolute;
  top: 50px;
  width: 100%; }

/* line 37, /Users/eric/Sites/GPSApp/app/styles/app.scss */
.panel {
  margin-top: 10px; }

/* line 41, /Users/eric/Sites/GPSApp/app/styles/app.scss */
#map-canvas {
  height: 100%;
  margin: 0;
  padding: 0;
  width: 100%;
  min-height: 700px;
  border: 1px solid #333; }

/* line 43, /Users/eric/Sites/GPSApp/app/styles/app.scss */
.colorTrace {
  display: block;
  width: 20px;
  height: 20px;
  border: 1px solid #ccc;
  float: left; }

/* line 49, /Users/eric/Sites/GPSApp/app/styles/app.scss */
.markerPerso {
  background: #fff;
  min-height: 20px;
  min-width: 23px;
  margin-bottom: 10px;
  color: #333;
  padding: 4px;
  border: 1px solid #666;
  position: relative;
  top: -10px;
  opacity: 0.9;
  text-align: center; }

/* line 63, /Users/eric/Sites/GPSApp/app/styles/app.scss */
.markerPersoWP {
  opacity: 0.6; }

/* line 67, /Users/eric/Sites/GPSApp/app/styles/app.scss */
.markerPerso .center-caret {
  position: absolute;
  left: 0px;
  bottom: 2px;
  height: 10px;
  text-align: center;
  width: 100%; }

/* line 75, /Users/eric/Sites/GPSApp/app/styles/app.scss */
.markerPerso .fa-caret-down {
  font-size: 30px;
  color: #666; }
